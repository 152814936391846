import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { AuthService } from '@konnektu/auth';
import { INSTANCE_CODE } from '@konnektu/tokens';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';
import { CompletionResponseDto } from './models/completion-response';
import { MessageDto } from './models/message-dto';

@Injectable({
  providedIn: 'root'
})
export class GigachatService {
  private readonly tenantCode = inject(INSTANCE_CODE);

  private readonly authService = inject(AuthService);

  private readonly http = inject(HttpClient);

  private readonly currentMessages = signal<MessageDto[]>([]);

  isGigachatEnabled = this.http
    .get<void>(`${this.tenantCode}/api/uzilla/v1/gigachat`)
    .pipe(
      map(() => true),
      catchError(() => of(false)),
      shareReplay(1)
    );

  getCompletion(request: string) {
    return this.http.post<CompletionResponseDto>(
      `${this.tenantCode}/api/uzilla/v1/gigachat/completions`,
      { messages: [{ role: 'user', content: request }] }
    );
  }

  getChatResponseStream(content: string) {
    const userMessage: MessageDto = { role: 'user', content };
    this.addMessage(userMessage);

    return new Observable<MessageDto>((observer) => {
      const message: MessageDto = {
        content: '',
        role: 'assistant'
      };

      const accessToken = this.authService.accessToken$.value;
      if (!accessToken) {
        observer.error(new Error('No access token available'));
        return;
      }

      fetch(
        `${this.tenantCode}/api/uzilla/v1/gigachat/completions?stream=true`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/event-stream',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            messages: this.currentMessages()
          })
        }
      )
        .then(async (response) => {
          if (!response.body) {
            observer.error(new Error('No response body'));
            return;
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let buffer = '';

          let isReading = true;
          while (isReading) {
            const { done, value } = await reader.read();
            if (done) {
              isReading = false;
              break;
            }

            buffer += decoder.decode(value, { stream: true });
            const lines = buffer.split('\n');
            buffer = lines.pop() || '';

            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = line.slice(6);

                if (data === '[DONE]') {
                  this.addMessage(message);
                  observer.complete();
                  return;
                }

                try {
                  const parsed = JSON.parse(data) as {
                    choices: {
                      delta: {
                        content: string;
                        functions_state_id?: string;
                      };
                    }[];
                  };

                  const newContent = parsed.choices[0].delta.content;

                  if (parsed.choices[0].delta.functions_state_id) {
                    message.functions_state_id =
                      parsed.choices[0].delta.functions_state_id;
                  }

                  await new Promise((resolve) => setTimeout(resolve, 100));
                  message.content += newContent;

                  observer.next(message);
                } catch (error) {
                  observer.error(error);
                }
              }
            }
          }
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  getFileUrl(fileId: string) {
    return `${this.tenantCode}/api/uzilla/v1/gigachat/files/${fileId}/content`;
  }

  private addMessage(message: MessageDto) {
    this.currentMessages.update((messages) => [...messages, message]);
  }

  clearHistory() {
    this.currentMessages.set([]);
  }
}
